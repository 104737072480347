@import "styles/utils.module";
@import "styles/color.module";


.container {
  width: 100%;
  height: 100px;
  background-color: $lightBackground;
  padding-left: 48px;
  padding-right: 40px;
  @extend .flexRow;
  @extend .justifyContentSpaceBetween;
  @extend .alignItemsCenter;
}

.mobileGradeWrapper {
  margin-top: 20px;
  background-color: $lightBackground;
  border-radius: 5px;
  @extend .flexRow;
  @extend .justifyContentSpaceBetween;
  padding: 11px 20px;
}

.mobileRewardContainer {
  @extend .flexRow;
  text-align: center;
  background-color: $lightBackground;
  padding: 20px 0;
  @extend .justifyContentCenter;
}

.nameWrapper {
  margin-top: 16px;
  line-height: 0;
  @extend .alignItemsCenter;
  @extend .justifyContentCenter;
}

.name {
  font-size: 28px;
  vertical-align: middle;
  line-height: 100%;
  font-weight: bold;
  color: $typo4;
}

.arrow {
  width: 20px;
  height: 20px;
  vertical-align: middle;
  margin-left: 4px;
}

@include desktop {
  .arrow {
    width: 16px;
    height: 16px;
    vertical-align: middle;
    margin-left: 2px;
  }
}

@include mobile {
  .name {
    margin-left: 8px;
  }
}


.rewardContainer {
  @extend .flexRow;
  text-align: center;
}

.gradeIcon {
  width: 24px;
  height: 24px;
  vertical-align: middle;
  margin-right: 4px;
}

.gradeName {
  vertical-align: middle;
  font-size: 18px;
  font-weight: bold;
  line-height: 100%;
  color: $silver;
}

.gradeReward {
  border-radius: 9999px;
  border: 1px solid $tableBorder;
  line-height: 0;
  @extend .flexRow;
  @extend .alignItemsCenter;
  @extend .justifyContentCenter;
  width: 98px;
  height: 24px;
}

.gradeRewardText {
  font-size: 16px;
  color: $typo2;
  font-weight: 500;
  line-height: 100%;
}

.gradeRewardWrapper {
  display: inline-block;
  vertical-align: middle;
  margin-left: 20px;
  @extend .cursorPointer;
}

.rewardWrapper {
  position: relative;
  width: 100%;
  @extend .justifyContentCenter;
  @extend .alignItemsCenter;
  @extend .flexCol;
  @extend .cursorPointer;
}

.reviewBubbleWrapper {
  width: auto;
  text-align: center;
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  margin-top: 4px;
  margin-left: -20px;
  margin-right: -20px;
}

.reviewBubbleContent {
  font-size: 13px;
  line-height: 120%;
  color: $brand;
  font-weight: 500;
  word-break: keep-all;
  word-wrap: break-word;
}

.rewardKey {
  display: block;
  font-size: 13px;
  font-weight: 500;
  line-height: 100%;
  color: $typo3;
  margin-bottom: 8px;
}

.rewardValue {
  font-size: 16px;
  line-height: 100%;
  font-weight: bold;
  color: $brand;
}

.verticalBorder {
  height: 48px;
  border-right: 1px solid $tableBorder;
}


.profileImage {
  width: 80px;
  height: 80px;
  border-radius: 9999px;
  overflow: hidden;
  position: relative;
}

.profileImageBorder {
  border: 1px solid $tableBorder;
}


@include desktop {
  .container {
    height: 98px;
    padding-left: 24px;
    padding-right: 0;
  }

  .mobileRewardContainer {
    padding: 22px 20px 23px 20px;
  }

  .name {
    font-size: 22px;
  }

  .gradeName {
    font-size: 16px;
  }

  .gradeReward {
    height: 22px;
    width: 89px;
  }

  .gradeRewardText {
    font-size: 14px;
  }

  .gradeRewardWrapper {
    margin-left: 12px;
  }

  .verticalBorder {
    height: 42px;
  }

  .profileImage {
    width: 72px;
    height: 72px;
  }
}

@include mobile {
  .rewardWrapper {
    width: 100%;
  }

  .mobileContainer {
    padding: 24px 20px;
  }
}

.linkContainer {
  @extend .flexCol;
  @extend .alignItemsCenter;
  margin-top: 12px;
  & > button {
    border: none;
  }
  background: none;
}

.link {
  color: $typo2;
  font-size: 13px;
  font-weight: 500;
  line-height: 100%;
  text-align: center;
}

.linkShareContainer {
  height: 24px;
  @extend .flexRow;
  @extend .alignItemsCenter;
  @extend .justifyContentCenter;
  background-color: $border;
  padding: 0 12px;
  border-radius: 9999px;
  margin-top: 8px;
  margin-bottom: 24px;
  @extend .cursorPointer;
  position: relative;
  border: none;
}

.linkShareText {
  font-size: 14px;
  font-weight: 500;
  line-height: 100%;
  color: $typo2;
}

.linkShareIcon {
  margin-right: 2px;
}

.modalContainer {
  padding-top: 24px;
}

.title {
  margin: 0;
  font-weight: bold;
  line-height: 140%;
  color: $typo3;
  font-size: 26px;
}

.subTitle {
  display: block;
  margin-top: 4px;
  font-weight: 500;
  line-height: 140%;
  color: $typo3;
  font-size: 15px;
}

.linkTitle {
  display: block;
  margin-top: 40px;
  font-size: 15px;
  font-weight: 500;
  line-height: 100%;
  color: $typo3;
}

.linkSubTitle {
  display: block;
  margin-top: 8px;
  font-size: 13px;
  font-weight: 500;
  line-height: 100%;
  color: $typo2;
  margin-bottom: 12px;
}

.titleRow {
  line-height: 0;
  margin-top: 24px;
  margin-bottom: 12px;
}

.titleRowTitle {
  font-size: 15px;
  font-weight: 500;
  line-height: 100%;
  color: $typo3;
  vertical-align: middle;
}

.titleRowSubTitle {
  font-size: 13px;
  font-weight: 500;
  line-height: 100%;
  color: $typo1;
  vertical-align: middle;
  margin-left: 4px;
}

.linkRow {
  @extend .flexRow;
  @extend .alignItemsCenter;
  margin-bottom: 12px;
  &:last-of-type {
    margin-bottom: 0;
  }
}

.linkIcon {
  width: 32px;
  height: 32px;
  margin-right: 12px;

  & > circle {
    fill: $lightBackground;
  }

  & path {
    fill: $typo3;
  }

  p path {
    fill: $typo3;
  }
}

.linkInputWrapper {
  flex: 1;
}

.submitButtonWrapper {
  padding: 6px 0;
}

.modalScrollContainer {
  height: 100%;
  overflow: auto;
}

.shareModal {
  position: absolute;
  height: 56px;
  padding: 0 12px 0 12px;
  background-color: white;
  border-radius: 5px;
  //border: 1px solid $border;
  left: 50%;
  transform: translateX(-50%);
  top: 40px;
  @extend .flexRow;
  @extend .alignItemsCenter;
  box-shadow: 0 0 6px rgba(25, 25, 25, 0.15);
  z-index: 50;

  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 160px;
    width: 0;
    height: 0;
    border: 10px solid transparent;
    border-bottom-color: white;
    border-bottom-width: 20px;
    border-top: 0;
    margin-left: -50%;
    z-index: -1;
    margin-top: -5px;
    //box-shadow: 0 0 5px black ;
  }
}

.shareModal > button {
  background: none;
  border: none;
  padding: 0;
  line-height: 0;
}

.kakaoIcon {
  width: 40px;
  height: 40px;
  margin-right: 20px;
  @extend .cursorPointer;
}

.copyIcon {
  width: 40px;
  height: 40px;
  margin-right: 20px;
  @extend .cursorPointer;
}

.closeIcon {
  width: 24px;
  height: 24px;
  @extend .cursorPointer;
}


@include tablet {
  .shareModal {
    height: 48px;
    top: 36px;
    padding: 0 12px;
    &:after {
      left: 140px;
    }
  }

  .kakaoIcon {
    height: 32px;
    width: 32px;
    margin-right: 16px;
  }

  .copyIcon {
    width: 32px;
    height: 32px;
  }
}
