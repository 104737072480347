@import "styles/utils.module";
@import "styles/color.module";


.container {
  padding-top: 20px;
}

.titleNickname {
  font-size: 26px;
  line-height: 100%;
  font-weight: 700;
  color: $typo4;
  display: block;
}

@include tablet {
  .container {
    padding-top: 0;
  }

  .titleNickname {
    font-size: 22px;
  }
}

.profileImageWrapper {
  width: 80px;
  height: 80px;
  position: relative;
  border-radius: 9999px;
  overflow: hidden;
}

@include tablet {
  .profileImageWrapper {
    width: 72px;
    height: 72px;
  }
}

@include mobile {
  .profileImageWrapper {
    width: 68px;
    height: 68px;
    margin-top: 24px;
  }
}

.contentNickname {
  font-weight: bold;
  font-size: 16px;
}

@include tablet {
  .contentNickname {
    font-size: 15px;
  }
}

.badge {
  width: 16px;
  height: 16px;
  margin-left: 4px;
}

.creatorSection {

}

@include tablet {
  .creatorSection {
    padding: 0 20px;
  }
}

.contentSection {
  margin-top: 60px;
}

@include tablet {
  .contentSection {
    margin-top: 40px;
    padding: 0 20px;
  }
}

@include mobile {
  .contentSection {
    padding-top: 4px;
    margin-top: 0;
    padding-left: 0;
    padding-right: 0;
  }
}

.paletteCardWrapper {
  margin-bottom: 32px;
  flex: 0 0 50%;
  padding: 0 0;
  &:nth-child(2n) {
    padding-left: 1.5px;
  }

  &:nth-child(2n+1) {
    padding-right: 1.5px;
  }
}

.introduction {
  font-weight: 500;
  line-height: 140%;
  font-size: 14px;
  color: $typo2;
  white-space: pre-wrap;
  text-align: center;
  margin-top: 8px;
}

.snsSection {
  margin-top: 16px;
  line-height: 0;
}

.snsIconWrapper {
  margin-left: 16px;
  &:first-of-type {
    margin-left: 0;
  }
}

.snsIcon {
  width: 32px;
  height: 32px;
  & > circle {
    fill: $lightBackground;
  }
  & path {
    fill: $typo3;
  }
  p path {
    fill: $typo3;
  }

  & > g > path {
    fill: $typo3;
  }
}

.paletteWrapper {
  padding: 0 16px;
  margin-top: 20px;
}

.sectionTitle {
  font-size: 20px;
  line-height: 100%;
  font-weight: bold;
  color: $typo4;
}

@include tablet {
  .sectionTitle {
    font-size: 18px;
  }
}

.specialPriceNickname {
  color: $brand;
}

.col {
  margin-bottom: 32px;
  flex: 0 0 50%;
  padding: 0 0;
  &:nth-child(2n) {
    padding-left: 1.5px;

  }
  &:nth-child(2n+1) {
    padding-right: 1.5px;
  }
}

.storeCardWrapper {
  @extend .flexCol;
}

.deleteButtonContainer {
  @extend .flexRow;
  @extend .justifyContentEnd;
  @extend .alignItemsCenter;
  padding: 0 12px;
  margin-top: 12px;
}

.shareButton {
  border: 1px solid $gray300;
  height: 29px;
  width: 33px;
  @extend .flexRow;
  @extend .alignItemsCenter;
  @extend .justifyContentCenter;
  cursor: pointer;
}

.deleteButton {
  border: 1px solid $gray300;
  height: 29px;
  @extend .flexRow;
  @extend .alignItemsCenter;
  @extend .justifyContentCenter;
  font-weight: 600;
  line-height: 100%;
  color: $gray800;
  font-size: 13px;
  cursor: pointer;
  flex: 1;
  margin-right: 4px;
}

.topProductsWrapper {

}

.productsWrapper {
}


.specialPriceSection {
  margin-top: 24px;
  margin-bottom: 80px;
  padding: 0 20px;
}

@include mobile {
  .specialPriceSection {
    margin-bottom: 40px;
  }
}

.postProductSection {
  margin-top: 24px;
}


.errorContainer {
  text-align: center;
  margin-top: 60px;
}

.errorIcon {
  width: 60px;
  height: 60px;
}

.errorTitle {
  display: block;
  font-weight: 700;
  font-size: 20px;
  line-height: 100%;
  margin-top: 20px;
  color: $brand;
}

.errorMessage {
  display: block;
  margin-top: 8px;
  font-size: 16px;
  font-weight: 500;
  line-height: 120%;
  color: $typo2;
}

@include tablet {
  .errorIcon {
    width: 48px;
    height: 48px;
  }

  .errorTitle {
    font-size: 18px;
  }

  .errorMessage {
    font-size: 15px;
  }
}

@include mobile {
  .errorContainer {
    margin-top: 40px;
  }

  .errorIcon {
    width: 40px;
    height: 40px;
  }

  .errorTitle {
    margin-top: 16px;
  }
}

.addText {
  font-size: 15px;
  font-weight: 500;
  color: $typo3;
  text-align: center;
  word-break: keep-all;
  line-height: 140%;
}

.profileEditButton {
  height: 40px;
  border: 1px solid $brand;
  width: 100%;
  margin-top: 24px;
  font-size: 14px;
  font-weight: 500;
  color: $brand;
  flex: 1;
  @extend .flexRow;
  @extend .alignItemsCenter;
  @extend .justifyContentCenter;
  @extend .cursorPointer;
}

.addProductButtonWrapper {
  height: 40px;
  width: 100%;
  margin-top: 24px;
  font-size: 14px;
  font-weight: 600;
  color: white;
  background: $brand;
  flex: 2;
  margin-left: 8px;
  @extend .flexRow;
  @extend .alignItemsCenter;
  @extend .justifyContentCenter;
  @extend .cursorPointer;
}

.buttonContainer {
  @extend .flexRow;
  width: 100%;
}


.profileEditLabel {
  font-size: 15px;
  font-weight: 500;
  line-height: 100%;
  color: $typo3;
  margin-bottom: 12px;
  display: block;
}

.profileEditWarn {
  display: block;
  margin-top: 12px;
  font-size: 13px;
  font-weight: 500;
  line-height: 100%;
  color: $typo2;
}

.profileEditSubmitButtonContainer {
  padding: 8px 0 16px;
  margin-top: 40px;
}

.modalScrollContainer {
  height: 100%;
  overflow: auto;
}

.couponContainer {
  @extend .fullWidth;
  @extend .flexRow;
  height: 55px;
  @extend .relative;
  @extend .cursorPointer;
  margin-top: 12px;
}

.couponContentContainer {
  @extend .flexCol;
  @extend .alignItemsStart;
  @extend .justifyContentCenter;
  flex: 1;
  background-color: $gray900;
  padding: 0 16px;
}

.couponButtonContainer {
  @extend .flexRow;
  @extend .alignItemsCenter;
  @extend .justifyContentCenter;
  width: 64px;
  background-color: $gray900;
}

.couponInfo {
  color: white;
  font-weight: 600;
  font-size: 14px;
  line-height: 120%;
}

.couponExpire {
  margin-top: 6px;
  font-size: 12px;
  line-height: 100%;
  font-weight: 500;
  color: $gray500;
}

.couponCutter {
  @extend .absolute;
  right: 63px;
  bottom: 14px;
  & > div {
    width: 2px;
    height: 2px;
    border-radius: 999px;
    background-color: $gray200;
    margin-top: 3px;
    &:first-of-type {
      margin-top: 0;
    }
  }
}


.tagModalSearchBarContainer {
  @extend .flexRow;
  @extend .sidePadding;
  @extend .alignItemsCenter;
  height: 50px;
  @extend .sidePadding;
  position: sticky;
  top: 0;
  left: 0;
  z-index: 10;
  background-color: white;
}

.tagModalSearchBarBackButton {
  @extend .cursorPointer;
  margin-right: 16px;
}

.tagModalSearchBarInputWrapper {
  @extend .flexRow;
  @extend .fullFlex;
  @extend .alignItemsCenter;
  border-radius: 9999px;
  border: 1px solid $brandLight;
  padding: 0 20px;
  height: 36px;
  background-color: $brandLight;
}

.tagModalSearchBarInputWrapperFocused {
  border: 1px solid $brand;
  background-color: white;
}

.tagModalSearchBarForm {
  @extend .fullFlex;
}

.tagModalSearchBarInputFocused {
  background-color: white;
}

.tagModalSearchBarResetIcon {
  width: 16px;
  height: 16px;
  @extend .cursorPointer;
}

.tagModalSearchIconFocused {
  & path {
    stroke: $brand;
    fill: rgba(0, 0, 0, 0);
  }

  & path:first-of-type {
    fill: rgba(0, 0, 0, 0);
    //stroke: rgba(0, 0, 0, 0);
  }
}

.tagModalSearchIcon {
  width: 24px;
  height: 24px;

  & path {
    fill: rgba(0, 0, 0, 0);
  }

  & path:first-of-type {
    fill: rgba(0, 0, 0, 0);
    //stroke: rgba(0, 0, 0, 0);
  }

  margin-left: 12px;
  @extend .cursorPointer;
}


.tagModalSearchBarInput {
  width: 100%;
  padding: 0;
  margin: 0;
  border: 0;
  font-size: 15px;
  line-height: 100%;
  font-weight: 500;
  background-color: $brandLight;

  &::placeholder {
    color: $typo2;
  }
}

.noOptionFound {
  margin-top: 40px;
  font-size: 15px;
  font-weight: 500;
  line-height: 140%;
  color: $typo3;
  text-align: center;
}

.pseudoProductOptionSubmitButtonWrapper {
  height: calc(env(safe-area-inset-bottom) + 94px);
}

.productOptionSubmitButtonWrapper {
  padding: 6px 20px calc(env(safe-area-inset-bottom) + 16px) 20px;
  background-color: white;
  margin-top: 24px;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
}

.productOptionTaggerPreviewContainer {
  display: flex;
  width: 100%;
  max-width: 100%;
  overflow-x: auto;
  flex-wrap: nowrap;
  -ms-overflow-style: none;
  scrollbar-width: none;
  padding: 16px 0 16px 20px;
  border-bottom: 1px solid $border;
}

.productOptionTaggerPreviewContainer::-webkit-scrollbar {
  display: none;
}

.productOptionTaggerPreviewWrapper {
  position: relative;
  flex: 0 0 52px;
  @extend .noLineHeight;
  margin-right: 10px;
  &:last-of-type {
    margin-right: 20px;
  }
  min-width: 52px;
  height: 52px;
  //background-color: red;
  //position: relative !important;
  @extend .cursorPointer;
}

.productOptionTaggerPreviewRemove {
  width: 15px;
  height: 15px;
  position: absolute;
  z-index: 100000000;
  right: 2.5px;
  top: 2.5px;
}

.productOptionTaggerPreviewImageWrapper {
  border-radius: 9999px;
  width: 100%;
  height: 100%;
  border: 1px solid $border;
  overflow: hidden;
  position: relative;
}

.productOptionRow {
  height: 76px;
  background-color: $lightBackground;
  border-radius: 5px;
  padding: 0 12px 0 8px;
  position: relative;
  margin-top: 12px;
  @extend .flexRow;
  @extend .alignItemsCenter;
  @extend .cursorPointer;
}

.productOptionRowSelected {
  border: 1px solid $brand;
}

.productOptionRowCheckBox {
  position: absolute;
  top: 26px;
  right: 10px;
}

.productOptionRowThumbnailWrapper {
  width: 60px;
  height: 60px;
  border-radius: 9999px;
  position: relative;
  overflow: hidden;
  margin-right: 8px;
}

.productOptionRowBrandName {
  font-size: 12px;
  font-weight: 500;
  line-height: 100%;
  color: $typo2;
  display: block;
  margin-bottom: 4px;
}

.productOptionRowProductName {
  font-size: 13px;
  font-weight: 500;
  line-height: 100%;
  color: $typo4;
  display: block;
  margin-bottom: 6px;
}

.productOptionRowOptionName {
  font-size: 12px;
  font-weight: 500;
  line-height: 100%;
  color: $typo2;
  vertical-align: middle;
}

.productOptionRowPipe {
  vertical-align: middle;
  margin: 0 4px;
  width: 12px;
  height: 12px;
}

.titleBarContentWrapper {
  @extend .flexRow;
  @extend .justifyContentSpaceBetween;
}